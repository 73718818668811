import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import db, { appleSignIn, auth, getDataForUser, googleSignIn, googleSignOut, initializePushNotifications } from './firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { getAdditionalUserInfo, onAuthStateChanged, User } from 'firebase/auth';
import Routes, { Route } from './Routes';
import { Storage } from './components/Impersonation';
import { Metric, unsetMetricUser, setMetricUser, trackMetric } from './utils/metrics';
import BigSpinner from './components/BigSpinner';
import ScrollToTop from './components/ScrollToTop';
import * as Sentry from '@sentry/react';
import upsertUser from './utils/upsertUser';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './css/all.scss';
import Error from './components/Error';
import { UserDocData } from './types';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

type AppContextType = {};

type AuthContextType = {
  uid?: string;
  user: User | null;
  userData?: UserDocData;
  signInWithApple: () => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  impersonating: string | null;
  isOnboarding?: boolean;
};

const AppContext = createContext<AppContextType | null>(null);
const AuthContext = createContext<AuthContextType | null>(null);

function useAuth() {
  return useContext(AuthContext);
}
function useApp() {
  return useContext(AppContext);
}

export default function App() {
  return (
    <AppContext.Provider value={{}}>
      <ErrorBoundary fallback={<Error />}>
        <AuthProvider>
          <ScrollToTop />
          <Routes />
        </AuthProvider>
      </ErrorBoundary>
    </AppContext.Provider>
  );
}

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [impersonating, setImpersonating] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserDocData>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // TODO: merge this handler with the signIn work.
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        setMetricUser(authUser);
        setImpersonating(sessionStorage.getItem(Storage.Impersonating));

        console.log('initializing push notifications');
        await initializePushNotifications(authUser.uid);

        const userData = await upsertUser(authUser);
        setUserData(userData);

        Sentry.setUser({ id: authUser.uid });
      } else {
        unsetMetricUser();
      }

      setUser(authUser);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!user?.uid) return;

    return onSnapshot(doc(db, 'users', user.uid), (doc) => {
      setUserData(doc.data() as UserDocData);
    });
  }, [user?.uid]);

  // TODO: merge this handler with the onAuthStateChanged work.

  async function handleCredential(userCredential) {
    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    const userData = await getDataForUser(userCredential.user.uid);

    setUser(userCredential.user);
    setUserData(userData);

    trackMetric(additionalUserInfo?.isNewUser ? Metric.SIGN_UP : Metric.LOG_IN);

    if (userData?.onboardingStep) navigate(Route.Onboarding);
  }

  const signInWithGoogle = async () => {
    if (user) return;

    const userCredential = await googleSignIn();
    await handleCredential(userCredential);
  };

  const signInWithApple = async () => {
    if (user) return;

    const userCredential = await appleSignIn();
    await handleCredential(userCredential);
  };

  const signOut = async () => {
    await googleSignOut();
    sessionStorage.removeItem(Storage.Impersonating);
    navigate(Route.Root);
  };

  if (isLoading) return <BigSpinner />;

  return (
    <AuthContext.Provider
      value={{
        uid: user?.uid,
        user,
        userData,
        signInWithApple,
        signInWithGoogle,
        signOut,
        impersonating,
        isOnboarding: userData && !!userData.onboardingStep,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { useAuth, useApp };
