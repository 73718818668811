import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './App';
import { Capacitor } from '@capacitor/core';
import AppRoutes from './routes/AppRoutes';
import MarComCapcitorRoutes from './routes/MarComCapacitorRoutes';
import MarComRoutes from './routes/MarComRoutes';

const Route = Object.freeze({
  AboutUs: '/about',
  Accounts: '/accounts',
  Blog: '/blog',
  Budget: '/:timescale/:date',
  Goal: '/goals/:planId',
  Goals: '/goals',
  Help: '/help',
  Impersonate: '/impersonate',
  Onboarding: '/onboarding',
  Privacy: '/privacy',
  Recoveries: '/recoveries',
  Recovery: '/recoveries/:planId',
  Root: '/',
  Security: '/security',
  Settings: '/settings',
  Transaction: '/transactions/:transactionId',
  Transactions: '/transactions',
  UserInterface: '/ui',
  Welcome: '/welcome/:step',
});

/**
 * Generates a route by replacing the parameters in the static route string with corresponding values passed in with the same name.
 * @param route - The route string containing parameter placeholders in the format `:paramName`.
 * @param params - An object containing key-value pairs of parameter names and their values (without the colon).
 * @returns The generated route string with the parameter placeholders replaced by their values.
 * @throws Error if a parameter in the route string does not have a corresponding value in the params object.
 */
function generateRoute(route: string, params: Record<string, string | number> = {}) {
  const expectedParamCount = route.split(':').length - 1;
  const actualParamCount = Object.keys(params).length;

  if (expectedParamCount !== actualParamCount)
    throw new Error(`Route ${route} expected ${expectedParamCount} parameter(s) but got ${actualParamCount}`);

  return Object.entries(params).reduce((acc, [key, value]) => {
    if (!acc.includes(`:${key}`)) throw new Error(`Route ${route} does not have a parameter :${key}`);
    if (value === null || value === undefined || value.toString().length === 0)
      throw new Error(`A value for parameter ${key} in ${route} is required but was not provided`);
    return acc.replace(`:${key}`, value.toString());
  }, route);
}

function RequireAuth({ children, otherwise }: { children: React.ReactNode; otherwise?: React.ReactNode }): any {
  const { user } = useAuth();

  if (!user) {
    if (otherwise) return otherwise;
    return <Navigate to={Route.Root} replace />;
  }

  return children;
}

function Routes() {
  const { user } = useAuth();

  if (user) return <AppRoutes />;
  if (Capacitor.isNativePlatform()) return <MarComCapcitorRoutes />;
  return <MarComRoutes />;
}

export { Route, generateRoute, RequireAuth };
export default Routes;
