import { Navigate, Route as RouterRoute, Routes as RouterRoutes } from 'react-router-dom';
import AppLayout from '../layouts/AppLayout';
import HelpPage from '../pages/HelpPage';
import PrivacyPage from '../pages/PrivacyPage';
import SecurityPage from '../pages/SecurityPage';
import AboutUsPage from '../pages/AboutUsPage';
import HomePage from '../pages/HomePage';
import SettingsPage from '../pages/SettingsPage';
import DashboardPage from '../pages/DashboardPage';
import OnboardingPage from '../pages/OnboardingPage';
import AccountsPage from '../pages/AccountsPage';
import PlansPage, { PlansPageTab } from '../pages/PlansPage';
import TransactionsPage from '../pages/TransactionsPage';
import ImpersonationPage from '../pages/ImpersonationPage';
import UserInterfacePage from '../pages/UserInterfacePage';
import React from 'react';
import { RequireAuth, Route } from '../Routes';
import { useAuth } from '../App';

export default function AppRoutes() {
  function RequireOnboarding({ children }) {
    const { isOnboarding } = useAuth();

    if (isOnboarding) {
      return <Navigate to={Route.Onboarding} replace />;
    }

    return children;
  }

  return (
    <RouterRoutes>
      <RouterRoute element={<AppLayout />}>
        <RouterRoute path={Route.Help} element={<HelpPage />} />
        <RouterRoute path={Route.Privacy} element={<PrivacyPage />} />
        <RouterRoute path={Route.Security} element={<SecurityPage />} />
        <RouterRoute path={Route.AboutUs} element={<AboutUsPage />} />
        <RouterRoute
          path={Route.Settings}
          element={
            <RequireAuth otherwise={<HomePage />}>
              <SettingsPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Root}
          element={
            <RequireAuth otherwise={<HomePage />}>
              <RequireOnboarding>
                <DashboardPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Onboarding}
          element={
            <RequireAuth>
              <OnboardingPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Accounts}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <AccountsPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Goal}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <PlansPage tab={PlansPageTab.Goals} />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Goals}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <PlansPage tab={PlansPageTab.Goals} />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Recovery}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <PlansPage tab={PlansPageTab.Recoveries} />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Recoveries}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <PlansPage tab={PlansPageTab.Recoveries} />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Transaction}
          element={
            <RequireAuth>
              <TransactionsPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Transactions}
          element={
            <RequireAuth>
              <TransactionsPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Impersonate}
          element={
            <RequireAuth>
              <ImpersonationPage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.UserInterface}
          element={
            <RequireAuth>
              <UserInterfacePage />
            </RequireAuth>
          }
        />
        <RouterRoute
          path={Route.Budget}
          element={
            <RequireAuth>
              <RequireOnboarding>
                <DashboardPage />
              </RequireOnboarding>
            </RequireAuth>
          }
        />
      </RouterRoute>
    </RouterRoutes>
  );
}
