import { Route as RouterRoute, Routes as RouterRoutes } from 'react-router-dom';
import HelpPage from '../pages/HelpPage';
import PrivacyPage from '../pages/PrivacyPage';
import SecurityPage from '../pages/SecurityPage';
import AboutUsPage from '../pages/AboutUsPage';
import HomePage from '../pages/HomePage';
import React from 'react';
import { Route } from '../Routes';
import WelcomeLayout from '../layouts/WelcomeLayout';
import WelcomePage from '../pages/WelcomePage';
import MarComLayout from '../layouts/MarComLayout';

export default function MarComRoutes() {
  return (
    <RouterRoutes>
      <RouterRoute element={<WelcomeLayout />}>
        <RouterRoute path={Route.Welcome} element={<WelcomePage />} />
      </RouterRoute>
      <RouterRoute element={<MarComLayout />}>
        <RouterRoute path={Route.Help} element={<HelpPage />} />
        <RouterRoute path={Route.Privacy} element={<PrivacyPage />} />
        <RouterRoute path={Route.Security} element={<SecurityPage />} />
        <RouterRoute path={Route.AboutUs} element={<AboutUsPage />} />
        <RouterRoute path={Route.Root} element={<HomePage />} />
      </RouterRoute>
    </RouterRoutes>
  );
}
