import React, { useCallback, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { BsQuestionCircle } from 'react-icons/bs';
import BetterButton, { BetterButtonProps } from './BetterButton';

interface ConfirmButtonProps extends BetterButtonProps {
  confirmText?: string;
  children: React.ReactNode;
  onConfirm: () => void;
}

export default function ConfirmButton({
  confirmText = 'Are you sure?',
  children,
  onConfirm,
  beforeIcon,
  metric,
  variant,
  ...rest
}: ConfirmButtonProps) {
  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirm = useCallback(async () => {
    setIsConfirming(true);
  }, [setIsConfirming, isConfirming]);

  return (
    <div>
      <Stack gap={3}>
        <Stack direction="horizontal" gap={2}>
          <BetterButton
            metric={isConfirming ? metric : undefined}
            variant={isConfirming ? 'danger' : variant}
            beforeIcon={isConfirming ? <BsQuestionCircle /> : beforeIcon}
            onClick={isConfirming ? onConfirm : handleConfirm}
            {...rest}
          >
            {isConfirming ? confirmText : children}
          </BetterButton>
          {isConfirming && (
            <BetterButton variant="link" onClick={() => setIsConfirming(false)}>
              Cancel
            </BetterButton>
          )}
        </Stack>
      </Stack>
    </div>
  );
}
