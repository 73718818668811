import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { getApps, initializeApp } from 'firebase/app';
import {
  AuthCredential,
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  initializeAuth,
  OAuthProvider,
  signInWithCredential,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { collection, connectFirestoreEmulator, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { FirebaseAuthentication, SignInWithOAuthOptions } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { UserDocData } from './types';
import * as Sentry from '@sentry/react';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
const functions = getFunctions(firebaseApp);

const auth = Capacitor.isNativePlatform()
  ? initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    })
  : getAuth();
const db = getFirestore(firebaseApp);
let analytics = null;

const Collection = Object.freeze({
  users: 'users',
});

if (!Capacitor.isNativePlatform() && process.env.NODE_ENV !== 'production') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
} else {
  analytics = getAnalytics(firebaseApp);
}

const _getLinkTokens = httpsCallable(functions, 'getLinkTokens');
const connectInstitution = httpsCallable(functions, 'connectInstitution');
const guessRecurringTransactions = httpsCallable(functions, 'guessRecurringTransactions');
const deleteUserPlaidItem = httpsCallable(functions, 'deleteUserPlaidItem');
const reconnectInstitution = httpsCallable(functions, 'reconnectInstitution');
const getImpersonationToken = httpsCallable(functions, 'getImpersonationToken');
const syncTransactions = httpsCallable(functions, 'syncTransactions');
const _recalculateBudgets = httpsCallable(functions, 'recalculateBudgets');
const _wipeUser = httpsCallable(functions, 'wipeUser');

/**
 * Kicks off recalculation of budgets from a given date. If no date
 * is provided, then it will recalculate *all* budgets. See Budget.calculateAll()
 * for more details.
 *
 * Note: calling this function could take a very long time. As such, it is
 * not possible to await it.
 * @param {Date | undefined} date Optional date from whence to recalculate budgets.
 */
function recalculateBudgets(date?: Date) {
  return _recalculateBudgets({ date });
}

function getLinkTokens() {
  return _getLinkTokens();
}

const impersonate = async (token: string) => {
  try {
    return await signInWithCustomToken(auth, token);
  } catch (e) {
    console.error(e);
  }
};

const wipeUser = async () => {
  return _wipeUser();
};

const googleSignOut = async () => {
  await signOut(auth);
  await FirebaseAuthentication.signOut();
};

const googleSignIn = async () => {
  if (Capacitor.isNativePlatform()) {
    const result = await FirebaseAuthentication.signInWithGoogle();
    const credential = GoogleAuthProvider.credential(result.credential.idToken);
    return signInWithCredential(auth, credential);
  } else {
    return signInWithPopup(auth, new GoogleAuthProvider());
  }
};

const appleSignIn = async () => {
  const provider = new OAuthProvider('apple.com');

  if (Capacitor.isNativePlatform()) {
    let credential: AuthCredential;
    let props: SignInWithOAuthOptions;

    if (Capacitor.getPlatform() === 'ios') props = { skipNativeAuth: true };

    const result = await FirebaseAuthentication.signInWithApple(props);

    credential = provider.credential({
      idToken: result.credential.idToken,
      rawNonce: result.credential.nonce,
    });

    return await signInWithCredential(auth, credential);
  } else {
    return await signInWithPopup(auth, provider);
  }
};

const getDataForUser = async (uid: string) => {
  const collectionRef = collection(db, Collection.users);
  const userDoc = doc(collectionRef, uid);
  let dataSnapshot = await getDoc(userDoc);
  return dataSnapshot.data() as UserDocData;
};

const initializePushNotifications = async (uid: string) => {
  // Push notifications not supported on web
  if (!Capacitor.isNativePlatform()) {
    return console.log('not on native, not using push notifications');
  }

  const result = await PushNotifications.requestPermissions();
  console.log(`Push Notifications requestPermissions(): ${result.receive}`);
  if (result.receive === 'granted') {
    // Register with Apple / Google to receive push via APNS/FCM
    await PushNotifications.register();
  }

  await PushNotifications.addListener('registration', async (token) => {
    // This is the push token for Android
    let fcmToken = token.value;

    const docRef = doc(db, 'users', uid);
    const user = await getDoc(docRef);
    const fcmTokens = user.data().fcmTokens || [];

    // Duplicate registration, ignore
    if (fcmTokens.includes(fcmToken)) return;

    await setDoc(
      docRef,
      {
        fcmTokens: [...fcmTokens, fcmToken],
      },
      { merge: true },
    );
  });

  // Some issue with our setup and push will not work
  await PushNotifications.addListener('registrationError', (error) => {
    Sentry.captureException(error);
  });

  // Show us the notification payload if the app is open on our device
  await PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.log(`Push Notifications pushNotificationReceived: ${JSON.stringify(notification)}`);
  });

  // Method called when tapping on a notification
  await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    console.log(`Push Notifications pushNotificationActionPerformed: ${JSON.stringify(notification)}`);
  });
};

export default db;
export {
  analytics,
  appleSignIn,
  auth,
  Collection,
  connectInstitution,
  wipeUser,
  deleteUserPlaidItem,
  getDataForUser,
  getImpersonationToken,
  getLinkTokens,
  googleSignIn,
  googleSignOut,
  guessRecurringTransactions,
  impersonate,
  recalculateBudgets,
  initializePushNotifications,
  reconnectInstitution,
  syncTransactions,
};
