import { Route as RouterRoute, Routes as RouterRoutes } from 'react-router-dom';
import MarComCapacitorLayout from '../layouts/MarComCapacitorLayout';
import HomePageCapacitor from '../pages/HomePageCapacitor';
import React from 'react';
import { Route } from '../Routes';

export default function MobileRoutes() {
  return (
    <RouterRoutes>
      <RouterRoute element={<MarComCapacitorLayout />}>
        <RouterRoute path={Route.Root} element={<HomePageCapacitor />} />
      </RouterRoute>
    </RouterRoutes>
  );
}
