import React, { HTMLAttributes } from 'react';

interface NoWrapProps extends HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
}

export default function NoWrap({ children, ...rest }: NoWrapProps) {
  console.debug('NoWrap is being deprecated. Please use Bootstrap’s utility class instead: `<span className="text-nowrap">...</span>`.');

  return (
    <span style={{ whiteSpace: 'nowrap' }} {...rest}>
      {children}
    </span>
  );
}
