import React from 'react';
import { Alert, Badge, Card, Col, Container, Row, Stack } from 'react-bootstrap';
import useDocumentTitle from '../hooks/useDocumentTitle';
import {
  BsBalloonHeartFill,
  BsBandaid,
  BsBank,
  BsCalendarWeek,
  BsCartCheck,
  BsChevronRight,
  BsCloudDownload,
  BsCodeSlash,
  BsFillShieldLockFill,
  BsLightning,
  BsMoonStars,
  BsPencil,
  BsPeopleFill,
  BsPiggyBank,
  BsPlusSlashMinus,
  BsSafeFill,
  BsSearchHeart,
  BsUmbrella,
} from 'react-icons/bs';
import dayjs from 'dayjs';
import { Variant } from 'react-bootstrap/esm/types';
import pluralize from '../shared/pluralize';
import { IconType } from 'react-icons/lib';
import { useOutletContext } from 'react-router-dom';
import BetterButton from '../components/BetterButton';

function HomePage() {
  useDocumentTitle('Home');

  const { handleSignUp } = useOutletContext<{ handleSignUp: () => {} }>();

  return (
    <>
      <section id="hero" className="text-center">
        <div className="wrapper pb-0">
          <h1 className="display-4 fw-bold mb-4">Money Made Easy</h1>
          <div className="mx-auto" style={{ maxWidth: 800 }}>
            {/* When this changes, also change the content prop of <meta name="description" /> */}
            <p className="display-6 mb-4 px-sm-6">
              Build a budget that you’ll actually enjoy in a <span className="text-nowrap">few minutes.</span>
            </p>
            <div className="mb-5">
              <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3" onClick={handleSignUp}>
                Sign up
              </button>
            </div>
          </div>
          <div
            style={{
              width: '70%',
              maxWidth: 500,
              padding: '80% 0 0',
              backgroundImage: 'url(/marcom/hero-iphone-peek.png)',
              backgroundSize: '100%',
            }}
            className="mx-auto overflow-hidden"
          ></div>
        </div>
      </section>

      <section id="benefits">
        <div className="wrapper">
          <Stack gap={4}>
            <Container>
              <Row className="align-items-center">
                <Col md="6">
                  <h2 className="mb-3">Spend with Certainty</h2>
                  <p className="lead">
                    Lopery helps you stay on budget by providing you with a single, glanceable number that updates{' '}
                    <span className="text-nowrap">as you spend.</span>
                  </p>
                  <p className="lead">
                    Finally, take the guesswork out of answering the question,{' '}
                    <strong>
                      “Can I <span className="text-nowrap">afford it?”</span>
                    </strong>
                  </p>
                  {/* <p>
                    <BetterButton afterIcon={<BsChevronRight />} href="#" variant="link btn-lg" className="px-0 fw-light">
                      Learn about “Safe to Spend”
                    </BetterButton>
                  </p> */}
                </Col>
                <Col md="6" className="text-center">
                  <img src="/marcom/spend-with-certainty.png" className="img-fluid mx-auto" loading="lazy" />
                </Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <Row className="align-items-center">
                <Col md="6">
                  <h2 className="mb-3">Recover from the Unexpected</h2>
                  <p className="lead">
                    Most people abandon their budget, when unexpected expenses come up, which—let’s face it—happens{' '}
                    <span className="text-nowrap">all the time.</span>
                  </p>
                  <p className="lead">
                    Recover from large unexpected expenses by spending a little less for a while. It’s like a zero-interest payment plan{' '}
                    <span className="text-nowrap">to yourself.</span>
                  </p>
                  <p>
                    <BetterButton
                      afterIcon={<BsChevronRight />}
                      href="/blog/2024/11/29/recovery/"
                      variant="link btn-lg"
                      className="px-0 fw-light"
                    >
                      Learn about “Recover”
                    </BetterButton>
                  </p>
                </Col>
                <Col md="6" className="text-center">
                  <img src="/marcom/recover-from-unexpected.png" className="img-fluid mx-auto" loading="lazy" />
                </Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <Row className="align-items-center">
                <Col md="6">
                  <h2 className="mb-3">Plan for the Future</h2>
                  <p className="lead">
                    Set aside money for your goals. Whether it’s a vacation, a new car, saving for a rainy day, or all of the above, Lopery
                    makes <span className="text-nowrap">it easy.</span>
                  </p>
                  {/* <p>
                    <BetterButton afterIcon={<BsChevronRight />} href="/blog/2024/07/29/major-upgrades-goals-carryover-runway-and-more/" variant="link btn-lg" className="px-0 fw-light">
                      Learn about “Goals”
                    </BetterButton>
                  </p> */}
                </Col>
                <Col md="6" className="text-center">
                  <img src="/marcom/plan-for-future.png" className="img-fluid mx-auto" loading="lazy" />
                </Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <Row className="align-items-center">
                <Col md="6">
                  <h2 className="mb-2">Everything in One Place</h2>
                  <p className="lead">
                    Search for any transaction, across all your <span className="text-nowrap">linked accounts.</span>
                  </p>
                  <p className="lead">
                    Some of our customers say Lopery is worth setting up just to be able to use our search{' '}
                    <span className="text-nowrap">and filters.</span>
                  </p>
                  <hr className="w-25" />
                  <Stack gap={2} className="small-font">
                    <div>
                      <Badge bg="secondary">Blog Post</Badge>
                    </div>
                    <div>
                      <a href="/blog/2024/11/15/better-ledger/">A Better Ledger: Search & Filter Transactions</a>
                    </div>
                  </Stack>
                </Col>
                <Col md="6" className="text-center">
                  <img src="/marcom/everything-in-one-place.png" className="img-fluid mx-auto" loading="lazy" />
                </Col>
              </Row>
            </Container>
            <CallToAction onSignUp={handleSignUp} />
          </Stack>
        </div>
      </section>

      <section id="features-and-pricing" className="text-light bg-dark">
        <div className="wrapper">
          <h1 className="text-center fw-bold mb-4">Free Features</h1>

          <p className="lead text-center mb-5">
            Our mission is to help as many people achieve financial independence as possible, which is why we offer a{' '}
            <strong>generous free tier</strong> that gives you all the tools you need to create and{' '}
            <span className="text-nowrap">sustain a budget.</span>
          </p>

          <Container className="g-0">
            <Row>
              <FeatureCard
                icon={BsLightning}
                title="Auto Budget"
                description="Lopery automatically identifies your income and recurring expenses to determine your allowance."
              />
              <FeatureCard
                icon={BsCartCheck}
                title="Safe to Spend"
                description="Stay on budget with a single number that updates as you spend."
              />
            </Row>
            <Row>
              <FeatureCard
                icon={BsBandaid}
                title="Recover"
                description="Recoup the cost of budget-busting transactions by curtailing future spending."
              />
              <FeatureCard
                icon={BsPiggyBank}
                title="Goals"
                description="Plan for the future by setting aside a little bit each week or month."
              />
            </Row>
            <Row>
              <FeatureCard
                icon={BsSearchHeart}
                title="Ledger"
                description="Search and filter every transaction across all your accounts."
              />
              <FeatureCard
                icon={BsPencil}
                title="Edit Transactions"
                description="Rename a transaction or adjust its date so it makes sense to you."
              />
            </Row>
            <Row>
              <FeatureCard
                icon={BsPlusSlashMinus}
                title="Carryover"
                description="Over- or under-spending is automatically added to or deducted from your next allowance."
              />
              <FeatureCard
                icon={BsUmbrella}
                title="Runway"
                description="See how long your cash will last based on your spending both with and without your liabilities."
              />
            </Row>
            <Row>
              <FeatureCard
                icon={BsCalendarWeek}
                title="Week Dashboard"
                description="Make budgeting more predictable and track your spending week-by-week to smooth out fluctuations from large bills and paychecks."
              />
              <FeatureCard
                icon={BsMoonStars}
                title="Dark Mode"
                description="Lopery matches your device’s settings so it's always easy on the eyes."
              />
            </Row>
          </Container>

          <hr className="my-5" />

          <h1 className="text-center fw-bold mb-4">Unlimited Features</h1>

          <p className="lead text-center mb-5">
            Once Lopery has become part of your daily routine, you may consider becoming a paying customer to remove the limitations of our
            free tier and access features that cater <span className="text-nowrap">to power-users.</span>
          </p>

          <Container className="g-0">
            <Row>
              <FeatureCard icon={BsBank} title="Unlimited Bank Connections" description="Connect up to 3 banks for free." />
              <FeatureCard
                icon={BsPeopleFill}
                title="Partner Mode"
                description="Share your Lopery budget with your partner so you can both stay on track."
                isBeta
              />
            </Row>
            <Row>
              <FeatureCard
                icon={BsCloudDownload}
                title="Data Export"
                description="Download all your transaction data across all accounts. Import to Google Sheets or Excel."
                isBeta
              />
              <FeatureCard
                icon={BsCodeSlash}
                title="Developer API"
                description="Write code to talk directly to your Lopery data and build apps on top of our infrastructure. Please contact us for more information."
                isBeta
              />
            </Row>
          </Container>

          <PromoAlert variant="success" onSignUp={handleSignUp} />
        </div>
      </section>

      <section id="earning-trust" className="text-center">
        <div className="wrapper">
          <h1 className=" fw-bold mb-4">Earning Your Trust</h1>

          <p className="lead mb-5">
            A budget doesn’t work unless you use it. That’s why we designed Lopery to be as easy as possible to set up and use. But, when it
            comes to your money, we know we have to earn your trust. Here are a few more reasons to give{' '}
            <span className="text-nowrap">us a shot:</span>
          </p>

          <Stack gap={4} className="mx-auto">
            <Stack gap={2}>
              <div className="text-muted">
                <BsSafeFill size={48} />
              </div>
              <div className="lead fw-bolder">Bank-Grade Security</div>
              <div>All data is transfered using Transport Layer Security (TLS) and stored at rest using 256-bit AES encryption.</div>
            </Stack>
            <hr className="w-50 mx-auto" />
            <Stack gap={2}>
              <div className="text-muted">
                <BsFillShieldLockFill size={48} />
              </div>
              <div className="lead fw-bolder">Privacy Promised</div>
              <div>
                We promise that we will never sell your data to third parties. With your permission, we may use it to offer you financial
                products for <span className="text-nowrap">your consideration.</span>
              </div>
            </Stack>
            <hr className="w-50 mx-auto" />
            <Stack gap={2}>
              <div className="text-muted">
                <BsBalloonHeartFill size={48} />
              </div>
              <div className="lead fw-bolder">Friendly Support</div>
              <div>
                Get email-based help whenever you need it. We love our customers and are happy <span className="text-nowrap">to help.</span>
              </div>
            </Stack>
            <hr className="w-50 mx-auto" />
            <div>
              <blockquote className="mt-3 mb-0" cite="/blog/2024/11/08/why-aimee-loves-lopery/">
                Lopery is a system that’s a lot more sustainable than the alternatives and keeps budgeting front of mind in a{' '}
                <span className="text-nowrap">daily way.</span>
              </blockquote>
              <cite className="text-muted">— Aimee, a therapist and Lopery customer</cite>
              <div className="my-2">
                <img
                  src="/marcom/aimee-headshot.jpg"
                  alt="Aimee"
                  className="img-thumbnail rounded-circle shadow"
                  width="100"
                  height="100"
                />
              </div>
              <Stack gap={2} className="small-font mx-auto">
                <div
                  style={{ border: '1px solid var(--bs-border-color-translucent)', padding: '0.8em', borderRadius: 8 }}
                  className="mx-auto bg-white"
                >
                  <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-2">
                    <div>
                      <Badge bg="secondary">Blog Post</Badge>
                    </div>

                    <div>
                      <a href="/blog/2024/11/08/why-aimee-loves-lopery/" className="text-muted">
                        Why Does Aimee Love Lopery?
                      </a>
                    </div>
                  </div>
                </div>
              </Stack>
            </div>
            <hr className="w-50 mx-auto" />
            <div>
              <blockquote className="mt-3 mb-0" cite="/blog/2024/11/08/why-aimee-loves-lopery/">
                Lopery is the “layer cake” of budgeting: the frosting is my Safe to Spend and the layers are my Recovery, Goals, and Ledger.
                I’ve used many budgeting programs over the years, but Lopery is the easiest to use by far and it’s{' '}
                <span className="text-nowrap">(calorie) free!</span>
              </blockquote>
              <cite className="text-muted">— Gale, a Lopery customer</cite>
              <div className="my-2">
                <img src="/marcom/gale-headshot.png" alt="Gale" className="img-thumbnail rounded-circle shadow" width="100" height="100" />
              </div>
            </div>
          </Stack>

          <PromoAlert variant="secondary" onSignUp={handleSignUp} />
        </div>
      </section>

      {/* <section>
        <h1>Who are we?</h1>
        <p>Why you should trust us. Combined decades in software engineering.</p>
        <div>
          <h2>Avand</h2>
          <ul>
            <li>Airbnb</li>
            <li>Mystery Science: #1 science curriculum</li>
            <li>Budget to a free house</li>
          </ul>
        </div>
        <div>
          <h2>Morgan</h2>
          <ul>
            <li>Square & Gusto</li>
            <li>Degree in English</li>
            <li>KidBank</li>
          </ul>
        </div>
      </section> */}
    </>
  );
}

export default HomePage;

function PromoAlert({ variant, onSignUp }: { variant: Variant; onSignUp: () => void }) {
  const endOfMonth = dayjs().endOf('month');

  return (
    <Alert variant={variant} className="mt-4 text-center">
      <Stack gap={3}>
        <div>
          <strong>{dayjs().format('MMMM')} Special</strong>
        </div>
        <div style={{ maxWidth: 600 }} className="mx-auto">
          Sign up in the next {pluralize(endOfMonth.diff(dayjs(), 'days'), 'day')} and access Lopery Unlimited (normally $7.99/mo or $79/yr)
          for a year on us.
        </div>
        <div>
          <BetterButton className="btn-lg" onClick={onSignUp} variant={variant}>
            Sign up
          </BetterButton>
        </div>
      </Stack>
    </Alert>
  );
}

function CallToAction({ onSignUp }: { onSignUp: () => void }) {
  return (
    <Stack gap={2} className="text-center">
      <div>
        <BetterButton className="btn-lg" onClick={onSignUp} variant="primary">
          Sign up
        </BetterButton>
      </div>
      <div className="small-font text-muted">
        Our free tier includes everything you need to build a budget and <span className="text-nowrap">stick to it.</span>
      </div>
    </Stack>
  );
}

function FeatureCard({
  icon: Icon,
  title,
  description,
  isBeta = false,
}: {
  icon: IconType;
  title: React.ReactNode;
  description: string;
  isBeta?: boolean;
}) {
  return (
    <Col sm className="mb-3">
      <Card bg="dark" text="light" border="secondary" body className="h-100">
        <Card.Title>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              {isBeta ? (
                <Stack gap={2} direction="horizontal">
                  <div>{title}</div>
                  <Badge bg="secondary">BETA</Badge>
                </Stack>
              ) : (
                title
              )}
            </div>
            <div>
              <Icon size={24} />
            </div>
          </div>
        </Card.Title>
        <Card.Text style={{ opacity: 0.6 }} className="small-font">
          {description}
        </Card.Text>
      </Card>
    </Col>
  );
}
