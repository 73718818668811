import React from 'react';
import { Stack } from 'react-bootstrap';
import useDocumentTitle from '../hooks/useDocumentTitle';
import BetterButton from '../components/BetterButton';
import { BsApple, BsChevronRight, BsGoogle } from 'react-icons/bs';
import { useAuth } from '../App';

function HomePageCapacitor() {
  useDocumentTitle('Home');
  const { signInWithGoogle, signInWithApple } = useAuth();

  async function handleGoogleButtonClick() {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAppleButtonClick() {
    try {
      await signInWithApple();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <section className="hero">
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="wrapper">
            <Stack gap={3}>
              <h1>Enjoy spending with certainty.</h1>
              <hr />
              <h1>Recover from the unexpected.</h1>
              <hr />
              <h1>Achieve financial independence.</h1>
            </Stack>
          </div>
          <div className="wrapper">
            <Stack gap={3} direction="vertical">
              <BetterButton
                beforeIcon={<BsGoogle />}
                afterIcon={<BsChevronRight />}
                onClick={handleGoogleButtonClick}
                className="btn-lg"
                style={{ justifyItems: 'center' }}
              >
                Continue with Google
              </BetterButton>
              <BetterButton beforeIcon={<BsApple />} afterIcon={<BsChevronRight />} onClick={handleAppleButtonClick} className="btn-lg">
                Continue with Apple
              </BetterButton>
            </Stack>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomePageCapacitor;
